import Link from "next/link";

export function Footer() {
  return (
    <footer className="py-10 max-w-5xl flex-col md:flex-row mx-auto border-x border-b border-slate-100 flex items-center justify-between gap-8">
      <p className="text-slate-950 text-base">
        2024 &copy; All Rights Reserved
      </p>
      <div className="flex items-center gap-4 mb-16 md:mb-0">

      <Link href="https://x.com/gerawrdog" target="_blank">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-slate-950"

        >
          <path
            d="M12.6149 15.5763L2.81489 2.97627C2.585 2.68069 2.79563 2.25 3.1701 2.25H5.02993C5.16879 2.25 5.29988 2.31411 5.38514 2.42373L15.1851 15.0237C15.4151 15.3194 15.2044 15.75 14.8299 15.75H12.9701C12.8312 15.75 12.7001 15.6859 12.6149 15.5763Z"
            stroke="currentColor"
          />
          <path
            d="M15 2.25L3 15.75"
            stroke="currentColor"
            strokeLinecap="round"
          />
        </svg>
        </Link>

        <Link href="https://t.me/gerawrdog" target="_blank"
        >
        <svg
          width="20"
          height="20"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-slate-950"
        >
          <path
            d="M15.75 3.75L1.5 9.375L6.75 10.125M15.75 3.75L13.875 15L6.75 10.125M15.75 3.75L6.75 10.125M6.75 10.125V14.25L9.1866 11.7922"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        </Link>
        <Link href="https://youtube.com/@gerardog" target="_blank">
        <svg
          width="20"
          height="20"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-slate-950"
          href="https://youtube.com/@gerardog"
        >
          <path
            d="M10.5 9L7.875 10.5V7.5L10.5 9Z"
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.5 9.53051V8.46918C1.5 6.29767 1.5 5.21192 2.17912 4.51332C2.85824 3.81472 3.92742 3.78448 6.06577 3.724C7.07905 3.69534 8.1141 3.6748 9 3.6748C9.8859 3.6748 10.921 3.69534 11.9342 3.724C14.0726 3.78448 15.1417 3.81472 15.8209 4.51332C16.5 5.21192 16.5 6.29767 16.5 8.46918V9.53051C16.5 11.702 16.5 12.7878 15.8209 13.4863C15.1417 14.1849 14.0726 14.2152 11.9343 14.2757C10.921 14.3043 9.8859 14.3249 9 14.3249C8.1141 14.3249 7.079 14.3043 6.0657 14.2757C3.92738 14.2152 2.85823 14.1849 2.17911 13.4863C1.5 12.7878 1.5 11.702 1.5 9.53051Z"
            stroke="currentColor"
          />
        </svg>
        </Link>
      </div>
    </footer>
  );
}
