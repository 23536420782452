export function Heading({
  backdrop,
  children,
}: {
  backdrop: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <span
        aria-hidden="true"
        aria-disabled="true"
        className="text-center select-none text-7xl block leading-none sm:text-8xl md:text-[136px] lg:text-[164px] font-black text-fuchsia-600/5"
      >
        {backdrop}
      </span>
      <h2 className="text-slate-950 text-xl sm:text-2xl md:text-3xl lg:text-4xl w-max font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        {children}
      </h2>
    </>
  );
}
