"use client";

import * as React from "react";

function NavLink({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  const [activeSection, setActiveSection] = React.useState("hey");

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const currentSection = entry.target.getAttribute("data-name") || "";
            setActiveSection(currentSection);
          }
        });
      },
      {
        threshold: 0.5,
        rootMargin: "-50% 0px -50% 0px",
      }
    );

    document.querySelectorAll("section[data-name]").forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section[data-name]");
      let currentActiveSection = "";

      sections.forEach((section: any) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
          currentActiveSection = section.getAttribute("data-name") || "";
        }
      });

      setActiveSection(currentActiveSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isActive = href === activeSection;

  return (
    <li
      data-active={isActive}
      onClick={() => {
        const element: HTMLElement | null = document.querySelector(
          `[data-name="${href}"]`
        );

        window.scrollTo({
          top: element?.offsetTop,
          behavior: "smooth",
        });
      }}
      className={`group text-base overflow-hidden flex items-center justify-center gap-1.5 text-center rounded-full ${
        isActive
          ? "bg-fuchsia-600 text-white h-9 px-3 w-auto"
          : "bg-transparent text-slate-950 hover:bg-fuchsia-600/10 w-9 md:w-auto h-9 md:px-3"
      } shrink-0 cursor-pointer`}
    >
      {children}
    </li>
  );
}

export function Navbar() {
  return (
    <nav className="bg-white/70 rounded-full w-max p-1 border ring-4 ring-fuchsia-600/5 border-fuchsia-600/20 left-1/2 -translate-x-1/2 z-[99] backdrop-blur-lg backdrop-saturate-200 fixed bottom-4">
      <ul className="flex w-max items-center gap-0.5">
        <NavLink href="hey">
          <span>👋🏻</span>
          <span className="hidden group-data-[active=true]:block md:block">
            Hey
          </span>
        </NavLink>
        <NavLink href="projects">
          <span>🚀</span>
          <span className="hidden group-data-[active=true]:block md:block">
            Projects
          </span>
        </NavLink>
        <NavLink href="nfts">
          <span>🖼️</span>
          <span className="hidden group-data-[active=true]:block md:block">
            NFTs
          </span>
        </NavLink>
        <NavLink href="reviews">
          <span>⭐</span>
          <span className="hidden group-data-[active=true]:block md:block">
            Reviews
          </span>
        </NavLink>
        <NavLink href="story">
          <span>📜</span>
          <span className="hidden group-data-[active=true]:block md:block">
            Story
          </span>
        </NavLink>
      </ul>
    </nav>
  );
}
