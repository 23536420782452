"use client";

import * as React from "react";

// @components
import Image from "next/image";
import Slider from "react-slick";
import { Heading, Button } from "@/components";

const REVIEWS = [
  {
    name: "@hexpulse2307",
    image:
      "/hp.jpg",
    message:
      "One cool young dude with a big future ahead - more power to you Gerardo",
  },
  {
    name: "@diamond_dad23",
    image:
      "/diamonddad.jpg",
    message: "Man I wish I could subscribe twice.",
  },
  {
    name: "@BenHEXwin",
    image:
      "/ben.jpg",
    message: "Excellent charts! Brilliant analysis! Great work Gerardo!",
  },
  {
    name: "@CryptoWealthTeam",
    image:
      "/wealth.jpg",
    message: "So much value and content!!",
  },
  {
    name: "@grahamreisbach6281",
    image:
      "/graham.jpg",
    message: "Everything is better after a new Gerardo vid",
  },
  {
    name: "@Lorddrip",
    image:
      "/lorddrip.jpg",
    message: "Legend",
  },
];

export function Community() {
  const sliderRef = React.useRef<any>(null);

  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    arrow: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };

  return (
    <section
      data-name="reviews"
      className="max-w-5xl mx-auto border-b border-x border-slate-100 relative"
    >
      <div className="py-8 overflow-hidden md:pt-8 md:pb-12 lg:pt-12 lg:pb-16 border-b border-slate-100 relative">
        <Heading backdrop="Thoughts">Community Feedback</Heading>
      </div>
      <div className="pb-12 sm:pb-16 pt-16 sm:pt-24 md:pt-28 lg:pt-32 relative">
        <div className="hidden sm:block absolute top-1/2 -translate-y-1/2 h-max z-20">
          <Button onClick={() => sliderRef?.current?.slickPrev()}>Prev</Button>
        </div>
        <Slider ref={sliderRef} {...settings}>
          {REVIEWS.map(({ name, image, message }, key) => (
            <div key={key}>
              <p className="text-slate-950 mb-8 md:mb-12 relative text-xl sm:text-2xl md:text-3xl lg:text-4xl leading-snug font-semibold text-center [text-wrap:_balance] max-w-2xl mx-auto">
                <span className="text-7xl sm:text-8xl md:text-[136px] lg:text-[164px] absolute -translate-y-[45%] -translate-x-1/2 font-semibold text-fuchsia-600/5">
                  “
                </span>
                {message}
              </p>
              <div>
                <Image
                  width={256}
                  height={256}
                  src={image}
                  alt="user profile"
                  className="w-10 h-10 md:w-12 md:h-12 rounded-full mx-auto mb-4"
                />
                <p className="text-sm md:text-base font-semibold text-slate-950 text-center mx-auto">
                  {name}
                </p>
              </div>
            </div>
          ))}
        </Slider>
        <div className="hidden sm:block absolute top-1/2 -translate-y-1/2 right-0 h-max z-20">
          <Button dir="right" onClick={() => sliderRef?.current?.slickNext()}>
            Next
          </Button>
        </div>
        <div className="flex justify-center mt-12 items-center gap-16 sm:hidden">
          <Button onClick={() => sliderRef?.current?.slickPrev()}>Prev</Button>
          <Button dir="right" onClick={() => sliderRef?.current?.slickNext()}>
            Next
          </Button>
        </div>
      </div>
    </section>
  );
}
